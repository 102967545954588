<template>
  <div
    v-if="tableTabs && tableTabs.length"
    class="student-consequences-ladder-datatable-container"
  >
    <ActionPanel
      v-if="tableTabs"
      @tab-clicked="setActiveTab"
      class="mb-3"
      :tabs="tableTabs"
    >
    </ActionPanel>
    <DataTable
      v-if="activeLadder"
      :class="{
        'hide-table-body': !activeLadderSteps
      }"
      :items="activeLadderSteps || []"
      :fields="tableFields"
    >
      <template #order="{ item }">
        <td>{{ item.order }}</td>
      </template>
      <template #type="{ item }">
        <td>
          {{ item.action && item.action.name ? item.action.name : item.type }}
        </td>
      </template>
      <template #footer>
        <tfoot>
          <tr class="w-full border-top-1">
            <td colspan="3">Reset date: {{ activeLadder.reset_dates[0] }}</td>
          </tr>
        </tfoot>
      </template>
      <template #no-items-view><div></div></template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/v3components/shared/DataTable/DataTable"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import { useStore } from "vuex"
import { onMounted, computed, reactive } from "vue"

export default {
  name: "StudentConsequencesLadderTable",
  components: { ActionPanel, DataTable },
  setup() {
    const store = useStore()
    const state = reactive({
      activeTab: null
    })

    // Ladders
    const ladders = computed(() => store.getters["consequencesLadder/ladders"])
    const ladderTypes = computed(
      () => store.getters["consequencesLadder/ladderTypes"]
    )
    const activeLadder = computed(() => {
      return ladders.value && state.activeTab
        ? ladders.value[state.activeTab.value]
        : null
    })
    const activeLadderSteps = computed(() => {
      return activeLadder.value && activeLadder.value.steps
        ? activeLadder.value.steps
        : null
    })

    const getAllLadders = () => {
      store.dispatch("consequencesLadder/getAllLadders")
    }

    const tableTabs = computed(() =>
      ladderTypes.value
        ? ladderTypes.value.map((el) => {
            return {
              label: el == "both" ? "Combined (LTS & LTC)" : el,
              value: el
            }
          })
        : null
    )
    const tableFields = [
      { key: "order", label: "Infraction step", sorter: false, filter: false },
      { key: "type", label: "Action type", sorter: false, filter: false }
    ]
    const setActiveTab = (tab) => {
      state.activeTab = tab
    }

    onMounted(() => {
      getAllLadders()
    })
    return {
      state,
      tableTabs,
      setActiveTab,
      activeLadderSteps,
      activeLadder,
      tableFields
    }
  }
}
</script>

<style></style>

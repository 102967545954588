<template>
  <CContainer class="datatable-container substitue-datatable pb-2">
    <Loader :is-processing="state.isLoading" />
    <CRow class="mt-2">
      <CCol md="12" class="m-auto">
        <ActionPanel
          show-search
          show-export
          @on-search="searchByQuery"
          @on-export="exportUsers"
        ></ActionPanel>
        <div class="status-guides-container d-block">
          <div class="d-flex justify-content-between align-items-center">
            <BulkAction
              :actions="state.bulkActions"
              @click-action="handleBulkAction($event)"
            ></BulkAction>
            <Perpage
              v-if="state.pagination"
              :pagination="state.pagination"
              :showing-entries="true"
              @on-page-change="setPerPage"
            />
          </div>
        </div>

        <DataTable
          v-if="users"
          :items="users"
          :fields="state.dataTableFields"
          :sorter="{ external: true }"
          column-filter
          :no-items-view="{
            noResults: 'No items available',
            noItems: 'No items available'
          }"
          @update:sorter-value="sortByColumn"
        >
          <template #search_column>
            <div class="sort-icon-v3">
              <i class="ri-search-line" @click="toggleSearchByColumn"></i>
            </div>
          </template>

          <template #select-header>
            <div class="d-flex align-items-center">
              <input
                type="checkbox"
                class="me-2"
                ref="selectAllCheckbox"
                @change="selectUsers($event)"
              />
            </div>
          </template>

          <template #select="{ item }">
            <td class="mw-auto">
              <input
                v-model="state.selectedUsers"
                type="checkbox"
                :value="item.id"
              />
            </td>
          </template>

          <template #first_name="{ item }">
            <td>
              <b>{{ item.first_name ? item.first_name : "" }}</b>
            </td>
          </template>

          <template #first_name-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.first_name.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #last_name="{ item }">
            <td>
              <b>{{ item.last_name ? item.last_name : "" }}</b>
            </td>
          </template>

          <template #last_name-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.last_name.value"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #pin-header>
            <div class="d-block text-center float-none">
              <b>PIN</b>
            </div>
            <div class="d-flex align-items-center ms-3">
              <span
                :class="`flaticon-${state.showPins ? 'eye' : 'hide'} me-2 cursor-pointer`"
                @click="state.showPins = !state.showPins"
              />
            </div>
          </template>

          <template #pin="{ item }">
            <td>
              <div v-if="item.pin">
                {{ state.showPins ? item.pin.pin : "******" }}
              </div>
            </td>
          </template>

          <template #status="{ item }">
            <td>
              <LabelPill
                :class="`status-${item.status ? 'active' : 'inactive'}`"
                :icon="
                  item.status
                    ? 'ri-checkbox-circle-fill'
                    : 'ri-close-circle-fill'
                "
              >
                {{ item.status ? "Active" : "Inactive" }}
              </LabelPill>
            </td>
          </template>

          <template #action="{ item }">
            <td class="text-end">
              <Actions
                v-if="item.status == 0"
                :actions="inactiveUserActions"
                test-id="reactivate-substitute-user"
                @click-action="handleTableAction($event, item)"
              >
              </Actions>
              <Actions
                v-else
                :actions="activeUserActions"
                test-id="deactivate-substitute-user"
                @click-action="handleTableAction($event, item)"
              >
              </Actions>
            </td>
          </template>
        </DataTable>

        <Pagination
          v-if="
            state.pagination.pages &&
            state.pagination.pages > 1 &&
            !state.isLazyLoadingMode
          "
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          :active-page="state.pagination.activePage"
          :pages="state.pagination.pages"
          size="lg"
          align="center"
          @update:active-page="setActivePage"
        >
        </Pagination>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import CreateSubstituteForm from "@/v3components/Forms/CreateSubstituteForm.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import BulkAction from "@/v3components/shared/DataTable/BulkAction"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import LabelPill from "@/v3components/shared/Buttons/LabelPill.vue"
import Perpage from "@/v3components/shared/DataTable/Perpage.vue"
import Actions from "@/v3components/shared/DataTable/Actions"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import download from "../../helpers/downloadCSV"
import helpers from "../../helpers/index"
import { ref, reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"

export default {
  name: "SubstituteUsersDataTable",
  components: {
    Loader,
    ActionPanel,
    BulkAction,
    Perpage,
    DataTable,
    LabelPill,
    Actions,
    Pagination
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")
    const state = reactive({
      dataTableFields: [
        { key: "select", label: "", type: "tabular", filter: false },
        { key: "first_name", label: "First Name", type: "tabular" },
        { key: "last_name", label: "Last Name", type: "tabular" },
        {
          key: "pin",
          label: "PIN",
          type: "tabular",
          filter: false,
          sorter: false
        },
        { key: "status", label: "Status", type: "tabular", filter: false },
        {
          key: "action",
          label: "Action",
          type: "tabular",
          filter: false,
          sorter: false
        }
      ],
      filterParams: {
        sort_query: "created_at:desc",
        searchQuery: ""
      },
      columnSearchKeys: {
        globalQuery: "",
        first_name: { value: "", column: ["first_name"] },
        last_name: { value: "", column: ["last_name"] }
      },
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      bulkActions: [
        {
          id: 1,
          text: "Deactivate",
          icon: "ri-pause-circle-line",
          action: "deactivate"
        },
        {
          id: 2,
          text: "Generate PIN(s)",
          icon: "ri-shield-cross-line",
          action: "generatePINS"
        }
      ],
      selectedUsers: [],
      filter: false,
      isLoading: false,
      isLazyLoadingMode: false,
      showPins: false,
      searchTimeOut: null
    })

    const selectAllCheckbox = ref(null)

    const users = computed(() => store.getters["users/substituteUsers"])
    const editableUser = computed(() => store.getters["users/editableUser"])

    const activeUserActions = [
      { label: "Edit", icon: "ri-edit-line", action: "edit" },
      {
        label: "Deactivate",
        icon: "ri-close-circle-line",
        class: "important",
        action: "deactivate"
      }
    ]

    const inactiveUserActions = [
      { label: "Edit", icon: "ri-edit-line", action: "edit" },
      {
        label: "Reactivate",
        icon: "ri-corner-up-left-line",
        class: "activate",
        action: "reactivate"
      }
    ]

    onMounted(() => {
      initLazyLoading()
      getUsers()
    })

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            users &&
            users.value.length &&
            state.pagination.total > users.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              getUsers(state.pagination.activePage, false, true)
            }
          }
        }
      }
    }

    const bulkDelete = () => {
      store
        .dispatch("users/substituteDeleteBulk", {
          teacher_ids: state.selectedUsers.join()
        })
        .then(() => {
          getUsers()
          state.selectedUsers = []
          selectAllCheckbox.value.checked = false
        })
    }

    const selectUsers = (e) => {
      if (e.target.checked) {
        state.selectedUsers = []
        users.value.forEach((user) => {
          state.selectedUsers.push(user.id)
        })
      } else {
        state.selectedUsers = []
      }
    }

    const exportUsers = () => {
      state.isLoading = true
      const params = {
        page: state.pagination.activePage,
        per_page: [25, 50, 100].includes(state.pagination.per_page.value)
          ? state.pagination.per_page.value
          : "all",
        is_substitute: true
      }
      if (state.filterParams.searchQuery) {
        params.search_query = state.filterParams.searchQuery
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      store
        .dispatch("users/exportUsers", params)
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "substitute_users")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const generatePINS = () => {
      store
        .dispatch("users/substituteBulkPin", {
          users: state.selectedUsers.join()
        })
        .then(() => {
          getUsers()
          state.selectedUsers = []
          selectAllCheckbox.value.checked = false
        })
    }

    const getUsers = (page, noLoader, isLazyLoadingMode) => {
      state.pagination.activePage = page ? page : 1
      state.isLoading = !noLoader
      store
        .dispatch("users/getSubstituteUsers", {
          sort: state.filterParams.sort_query,
          search_query: state.filterParams.searchQuery,
          page: state.pagination.activePage,
          per_page: state.pagination.per_page.value
        })
        .then((response) => {
          const data = response.data
          if (isLazyLoadingMode) {
            store.commit("users/PUSH_USERS", data.data)
          } else {
            store.commit("users/SET_USERS", data.data)
          }
          const meta = data.meta
          if (meta) {
            state.pagination.total = meta.total
            state.pagination.from = meta.from
            state.pagination.to = meta.to
            state.pagination.pages = Math.ceil(meta.total / meta.per_page)
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const onCloseModal = () => {
      modal.close()
    }

    const editUser = (user) => {
      store.commit("users/SET_EDITABLE_SUBSTITUTE", user)
      modal.open(CreateSubstituteForm, {
        size: "md",
        title: "Edit Substitute",
        props: {
          editableMode: true,
          events: { closeModal: onCloseModal }
        }
      })
    }

    const toggleSubstitute = (user) => {
      state.isLoading = true
      store
        .dispatch(
          `users/${
            user.status
              ? "deactivateSubstituteTeacher"
              : "activateSubstituteTeacher"
          }`,
          { userId: user.id }
        )
        .then(() => {
          state.isLoading = false
          setTimeout(() => {
            getUsers(1, true)
          }, 500)
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          showErrorModal()
        })
    }

    const showErrorModal = () => {
      modal.open(InfoBox, {
        size: "md",
        title: "Something went wrong!",
        props: {
          message:
            state.reqResponse.message !=
            "Invalid error from CUS, please contact with administrator"
              ? state.reqResponse.message
              : "This change may take 24 hours to execute. Check tomorrow for this request to be updated. If no change, please contact the help desk."
        }
      })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.searchQuery = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.searchQuery =
                    state.filterParams.searchQuery +
                    `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.searchQuery = state.filterParams.searchQuery.slice(
            0,
            -2
          )
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.searchQuery = query ? '"' + query + '"' : query
        }
        getUsers()
      }
    }

    const resetSearchQuery = () => {
      state.filterParams.searchQuery = ""
      state.columnSearchKeys = {
        globalQuery: "",
        first_name: { value: "", column: ["first_name"] },
        last_name: { value: "", column: ["last_name"] }
      }
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].column[0]
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      getUsers()
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        getUsers()
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getUsers(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "deactivate" || event.action == "reactivate") {
        toggleSubstitute(item)
      }
      if (event.action == "edit") {
        editUser(item)
      }
    }

    const handleBulkAction = (event) => {
      if (event.action == "deactivate") {
        bulkDelete()
      }
      if (event.action == "generatePINS") {
        generatePINS()
      }
    }

    return {
      state,
      selectAllCheckbox,
      users,
      editableUser,
      activeUserActions,
      inactiveUserActions,
      bulkDelete,
      selectUsers,
      exportUsers,
      generatePINS,
      editUser,
      toggleSubstitute,
      searchByQuery,
      toggleSearchByColumn,
      sortByColumn,
      setPerPage,
      setActivePage,
      handleTableAction,
      handleBulkAction
    }
  }
}
</script>

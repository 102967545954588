<template>
  <CContainer class="">
    <Loader :is-processing="state.isLoading" />

    <CRow class="mt-4">
      <CCol md="12">
        <ActionPanel
          @tab-clicked="setActiveTableTab"
          show-sort
          class="mt-3"
          show-export
          show-search
          @on-search="searchByQuery"
          :tabs="state.tableTabs"
          @on-sort="toggleFilter"
          @on-export="handlePassLimits(state.pagination.activePage, true, true)"
        >
          <template #bottom-content>
            <CRow class="mt-3" v-if="state.isFilterVisible">
              <CCol class="justify-content-start">
                <CustomCheck
                  class="mt-2"
                  label="Group Limits"
                  v-model="state.filterParams.checkBoxes.group_limits"
                  @change="changeGL"
                ></CustomCheck>
                <CustomCheck
                  class="ms-4 mt-2"
                  label="Individual Students"
                  v-model="state.filterParams.checkBoxes.individual_students"
                  @change="changeIS"
                ></CustomCheck>
                <CustomCheck
                  class="ms-4 mt-2"
                  label="Student Number"
                  v-model="state.filterParams.checkBoxes.student_sis_id"
                  @change="changeSN"
                ></CustomCheck>
              </CCol>
              <CCol class="justify-content-end text-end">
                <BaseButton class="" solid rounded @click="handlePassLimits()"
                  >Filter</BaseButton
                >
                <BaseButton class="ms-4" rounded @click="clearAll()"
                  >Clear all</BaseButton
                >
              </CCol>
            </CRow>
          </template>
        </ActionPanel>
      </CCol>
      <div class="d-flex">
        <CCol
          v-if="state.filterParams.activeTab.value === 'active'"
          class="w-auto mt-2 pr-3 mb-2 ms-1 d-flex"
        >
          <BulkAction
            :actions="state.bulkActions"
            @click-action="handleBulkAction($event)"
          ></BulkAction>
        </CCol>
        <CCol class="w-auto mt-2 mb-2 d-flex justify-content-end">
          <PerPage
            v-if="state.pagination"
            :pagination="state.pagination"
            :showing-entries="true"
            @on-page-change="setPerPage"
          />
        </CCol>
      </div>
    </CRow>

    <DataTable
      v-if="passLimits"
      :items="passLimits"
      :fields="activeDataFields"
      :sorter="{ external: true }"
      column-filter
      :no-items-view="{
        noResults: 'No items available',
        noItems: 'No items available'
      }"
      @update:sorter-value="sortByColumn"
    >
      <template #search_column>
        <div class="sort-icon-v3">
          <i class="ri-search-line" @click="toggleSearchByColumn"></i>
        </div>
      </template>

      <template #delete-header="{}">
        <div>
          <CustomCheck
            :model-value="state.deleteCheckbox"
            @update:model-value="toggleAllForDelete"
          />
        </div>
      </template>

      <template #delete="{ item }">
        <td class="mw-auto">
          <CustomCheck
            :model-value="state.deleteCollection.includes(item.id)"
            @update:model-value="togglePassLimitForDelete(item)"
          />
        </td>
      </template>

      <template #for="{ item }">
        <td>
          <p v-if="item.for" class="m-0">
            {{ item.for }}
          </p>
        </td>
      </template>
      <template #for-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.for.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #student_sis_id="{ item }">
        <td>
          <div>
            {{
              item.limitable.student_sis_id ? item.limitable.student_sis_id : ""
            }}
          </div>
        </td>
      </template>

      <template #from_date="{ item }">
        <td>
          {{ item.from_date ? item.from_date : "" }}
        </td>
      </template>
      <template #from_date-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.from_date.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>
      <template #to_date="{ item }">
        <td>
          {{ item.to_date ? item.to_date : "" }}
        </td>
      </template>
      <template #to_date-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.to_date.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #updated_at="{ item }">
        <td>
          {{
            item.updated_at
              ? $helpers.transformDate(item.updated_at, "MM/DD/YYYY")
              : ""
          }}
        </td>
      </template>
      <template #updated_at-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.updated_at.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #limit="{ item }">
        <td>
          <b>{{ item.limit }}</b>
        </td>
      </template>
      <template #limit-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.limit.value"
          placeholder="Search by limit"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #recurrence_type="{ item }">
        <td>
          <div
            v-if="item.recurrence_type"
            class="d-flex align-items-center justify-content-between"
          >
            <div class="v3base-gray me-2">
              {{ item.recurrence_type }}
            </div>
          </div>
          <div class="v3base-gray" v-else>does not repeat</div>
        </td>
      </template>
      <template #recurrence_type-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.recurrence_type.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #reason="{ item }">
        <td class="v3comments-column">
          <div v-if="item.reason" class="v3comments-list">
            <div class="v3comment">
              {{ item.reason }}
            </div>
          </div>
        </td>
      </template>
      <template #reason-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.reason.value"
          placeholder="Search by reason"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #created_at="{ item }">
        <td>
          {{
            item.created_at
              ? $helpers.transformDate(item.created_at, "MM/DD/YYYY h:mm A")
              : ""
          }}
        </td>
      </template>
      <template #created_at-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.created_at.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #action="{ item }">
        <td class="text-end">
          <Actions
            :actions="studentPassLimitActions"
            @click-action="handleTableAction($event, item)"
          >
          </Actions>
        </td>
      </template>
      <template #action-filter>
        <div v-show="state.filter"></div>
      </template>
    </DataTable>

    <Pagination
      v-if="
        state.pagination.pages &&
        state.pagination.pages > 1 &&
        !state.isLazyLoadingMode
      "
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    >
    </Pagination>
  </CContainer>
</template>
<script>
import Loader from "@/v3components/shared/Loader/Loader"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import CustomCheck from "@/v3components/shared/Form/CustomCheck"
import BaseButton from "@/v3components/shared/Buttons/BaseButton"
import PerPage from "@/v3components/shared/DataTable/Perpage"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import Actions from "@/v3components/shared/DataTable/Actions"
import BulkAction from "@/v3components/shared/DataTable/BulkAction"
import helpers from "../../helpers/index"
import { useStore } from "vuex"
import { onMounted, computed, reactive, inject, watch } from "vue"
import CreateLimitStudentPassForm from "@/v3components/Forms/CreateLimitStudentPassForm"
import download from "@/helpers/downloadCSV"

export default {
  name: "StudentPassLimitsTable",
  components: {
    Loader,
    ActionPanel,
    CustomCheck,
    BaseButton,
    PerPage,
    DataTable,
    Pagination,
    BulkAction,
    Actions
  },
  setup() {
    const store = useStore()

    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const state = reactive({
      bulkActions: [
        {
          id: 1,
          text: "Delete",
          icon: "ri-delete-bin-line",
          action: "delete"
        }
      ],
      filter: false,
      isFilterVisible: false,
      isLazyLoadingMode: false,
      deleteCheckbox: false,
      deleteCollection: [],
      columnSearchKeys: {
        globalQuery: "",
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        for: { value: "", column: ["sortable_name"], sortBy: "sortable_name" },
        from_date: {
          value: "",
          column: ["from_date.raw"],
          sortBy: "from_date.raw"
        },
        to_date: { value: "", column: ["to_date.raw"], sortBy: "to_date.raw" },
        limit: { value: "", column: ["limit"], sortBy: "limit" },
        created_at: { value: "", column: ["created_on"], sortBy: "created_at" },
        updated_at: { value: "", column: ["updated_at"], sortBy: "updated_at" },
        recurrence_type: {
          value: "",
          column: ["recurrence_type"],
          sortBy: "recurrence_type"
        },
        reason: { value: "", column: ["reason"], sortBy: "reason" }
      },
      filterParams: {
        search_query: "",
        sort_query: "",
        activeTab: "",
        checkBoxes: {
          group_limits: false,
          individual_students: false,
          student_sis_id: false
        }
      },
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      dataTableFields: [
        {
          key: "delete",
          label: "",
          filter: false,
          _style: "width:50px",
          tabs: ["active"]
        },
        {
          key: "student_sis_id",
          filter: false,
          sorter: false,
          label: "Student number",
          tabs: ["active", "inactive"],
          _style: "min-width:150px"
        },
        {
          key: "for",
          label: "Student",
          tabs: ["active", "inactive"],
          _style: "min-width:120px"
        },
        {
          key: "from_date",
          label: "Start Date",
          tabs: ["active", "inactive"],
          _style: "min-width:135px"
        },
        {
          key: "to_date",
          label: "End Date",
          tabs: ["active", "inactive"],
          _style: "min-width:130px"
        },
        {
          key: "limit",
          label: "Limit",
          _style: "width:80px",
          tabs: ["active", "inactive"]
        },
        {
          key: "recurrence_type",
          label: "Repeat",
          tabs: ["active", "inactive"]
        },
        { key: "reason", Label: "Comment", tabs: ["active", "inactive"] },
        {
          key: "created_at",
          label: "Created on",
          _style: "min-width:160px",
          tabs: ["active", "inactive"]
        },
        {
          key: "action",
          label: "Action",
          _style: "width: 50px;",
          tabs: ["active"]
        }
      ],
      tableTabs: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" }
      ],
      isLoading: false,
      modal: {
        isVisible: {
          edit: false
        },
        activePass: null
      }
    })

    const studentPassLimitActions = [
      { label: "Edit", icon: "ri-edit-line", action: "edit" },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const passLimits = computed(() => store.getters["passLimits/passLimits"])

    const totalPassesCount = computed(() => {
      return state.pagination.total > passLimits.value.length
        ? state.pagination.total
        : passLimits.value.length
    })

    const passLimitIDCollection = computed(() =>
      passLimits.value.map((el) => el.id)
    )

    const activeDataFields = computed(() => {
      return state.dataTableFields
        .filter((field) =>
          field.tabs.includes(state.filterParams.activeTab.value)
        )
        .filter((field) =>
          !state.filterParams.checkBoxes.student_sis_id
            ? field.key !== "student_sis_id"
            : field
        )
    })

    onMounted(() => {
      initLazyLoading()
      state.filterParams.activeTab = state.tableTabs[0]
    })

    const setParamsForRequest = (page) => {
      state.pagination.activePage = page ? page : 1
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.activeTab) {
        params[state.filterParams.activeTab.value] = true
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      for (const key in state.filterParams.checkBoxes) {
        if (Object.hasOwnProperty.call(state.filterParams.checkBoxes, key)) {
          const item = state.filterParams.checkBoxes[key]
          if (item) {
            params[key] = true
          }
        }
      }
      return params
    }

    const handlePassLimits = (page, isExport, noLoader, isLazyLoadingMode) => {
      state.isLoading = !noLoader
      const params = setParamsForRequest(page)
      if (isExport) {
        exportCSVPassLimits(params)
      } else {
        getPassLimits(params, noLoader, isLazyLoadingMode)
      }
    }

    const getPassLimits = (params, noLoader, isLazyLoadingMode) => {
      state.isLoading = !noLoader
      store
        .dispatch("passLimits/getPassLimits", params)
        .then((response) => {
          const data = response.data
          if (data) {
            if (isLazyLoadingMode) {
              store.commit("passLimits/PUSH_PASS_LIMITS", data.data)
            } else {
              store.commit("passLimits/SET_PASS_LIMITS", data.data)
            }
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const exportCSVPassLimits = (params) => {
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("passLimits/getCSVpassLimitsExport", params)
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "pass-limits")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value = "", type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:${
                      col === "limit"
                        ? columnData.value
                        : '"' + columnData.value + '"'
                    }, `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        handlePassLimits()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handlePassLimits()
    }

    const editPassLimit = (pass) => {
      if (pass) {
        store.commit("passLimits/SET_EDITABLE_PASS_LIMITS", pass)
        state.modal.isVisible.edit = true
        state.modal.activePass = pass
        modal.open("edit-pass-limit")
      }
    }

    const togglePassLimitForDelete = (item) => {
      if (
        state.deleteCollection.length &&
        state.deleteCollection.includes(item.id)
      ) {
        state.deleteCheckbox = false
        state.deleteCollection = state.deleteCollection.filter(
          (el) => el != item.id
        )
      } else {
        state.deleteCollection.push(item.id)
        if (state.deleteCollection.length === passLimits.value.length) {
          state.deleteCheckbox = true
        }
      }
    }

    const toggleAllForDelete = () => {
      state.deleteCheckbox = !state.deleteCheckbox
      state.deleteCollection = state.deleteCheckbox
        ? passLimitIDCollection.value
        : []
    }

    const deletePassLimit = (passID, collection) => {
      if (passID) {
        state.isLoading = true
        store
          .dispatch("passLimits/deletePassLimit", passID)
          .then(() => {
            state.isLoading = false
            state.pagination.total = state.pagination.total - 1
          })
          .catch(() => (state.isLoading = false))
      }
      if (collection && collection.length) {
        state.isLoading = true
        store
          .dispatch("passLimits/deletePassLimits", collection)
          .then(() => {
            handlePassLimits()
            state.deleteCollection = []
            state.deleteCheckbox = false
          })
          .catch(() => (state.isLoading = false))
      }
    }

    const deletePassLimits = (collection) => {
      if (collection && collection.length) {
        state.isLoading = true
        store
          .dispatch("passLimits/deletePassLimits", collection)
          .then(() => {
            handlePassLimits()
            state.deleteCollection = []
            state.deleteCheckbox = false
          })
          .catch(() => (state.isLoading = false))
      }
    }

    const updatePassLimit = (pass) => {
      if (pass.id && pass.status) {
        state.isLoading = true
        store
          .dispatch("passLimits/updatePassLimit", pass.id)
          .then(() => (state.isLoading = false))
          .catch(() => (state.isLoading = false))
      }
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handlePassLimits()
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handlePassLimits(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const setActiveTableTab = (tab) => {
      resetFilterValues()
      state.filterParams.activeTab = tab
      helpers.scrollToTop()
      handlePassLimits()
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        for: { value: "", column: ["sortable_name"], sortBy: "sortable_name" },
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        from_date: {
          value: "",
          column: ["from_date.raw"],
          sortBy: "from_date.raw"
        },
        to_date: { value: "", column: ["to_date.raw"], sortBy: "to_date.raw" },
        limit: { value: "", column: ["limit"], sortBy: "limit" },
        created_at: { value: "", column: ["created_on"], sortBy: "created_at" },
        updated_at: { value: "", column: ["updated_at"], sortBy: "updated_at" },
        recurrence_type: {
          value: "",
          column: ["recurrence_type"],
          sortBy: "recurrence_type"
        },
        reason: { value: "", column: ["reason"], sortBy: "reason" }
      }
    }

    const resetParams = () => {
      state.filterParams = {
        search_query: "",
        sort_query: "",
        activeTab: state.tableTabs[0],
        checkBoxes: {
          group_limits: false,
          individual_students: false
        }
      }
    }

    const refreshDataTable = () => {
      resetParams()
      resetSearchQuery()
      handlePassLimits()
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            passLimits.value &&
            passLimits.value.length &&
            state.pagination.total > passLimits.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handlePassLimits(state.pagination.activePage, false, false, true)
            }
          }
        }
      }
    }

    const changeGL = () => {
      state.filterParams.checkBoxes.group_limits =
        !state.filterParams.checkBoxes.group_limits
    }

    const changeIS = () => {
      state.filterParams.checkBoxes.individual_students =
        !state.filterParams.checkBoxes.individual_students
    }

    const changeSN = () => {
      state.filterParams.checkBoxes.student_sis_id =
        !state.filterParams.checkBoxes.student_sis_id
    }

    const clearAll = () => {
      resetFilterValues()
      handlePassLimits()
    }

    const toggleFilter = () => {
      state.isFilterVisible = !state.isFilterVisible
    }

    const setPagination = (data) => {
      state.pagination = Object.assign(state.pagination, data)
    }
    const resetFilterValues = () => {
      state.isFilterVisible = false
      state.filterParams = {
        search_query: "",
        sort_query: "",
        activeTab: state.filterParams.activeTab,
        checkBoxes: {
          group_limits: false,
          individual_students: false
        }
      }
      setPagination({
        activePage: 1,
        total: 0,
        from: 0,
        to: 0,
        pages: 0
      })
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const handleBulkAction = (event) => {
      if (event.action === "delete") {
        actionDialog.open(deletePassLimits, {
          args: state.deleteCollection,
          props: {
            danger: true,
            title: "Bulk delete",
            question: "Are you sure?"
          }
        })
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deletePassLimit, {
          args: item.id,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
      if (event.action == "edit") {
        modal.open(CreateLimitStudentPassForm, {
          size: "md",
          title: "Edit student pass limits",
          props: {
            editablePassLimit: item,
            isInView: true
          }
        })
      }
    }

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "createStudentPassLimit") {
          handlePassLimits(state.pagination.activePage, false, true)
        }
      }
    )

    return {
      state,
      passLimits,
      totalPassesCount,
      passLimitIDCollection,
      activeDataFields,
      setParamsForRequest,
      handlePassLimits,
      getPassLimits,
      exportCSVPassLimits,
      searchByQuery,
      handleQuerySearch,
      sortByColumn,
      editPassLimit,
      togglePassLimitForDelete,
      toggleAllForDelete,
      deletePassLimit,
      updatePassLimit,
      setPerPage,
      setActivePage,
      setActiveTableTab,
      resetSearchQuery,
      resetParams,
      refreshDataTable,
      clearAll,
      changeGL,
      changeIS,
      toggleFilter,
      toggleSearchByColumn,
      handleBulkAction,
      studentPassLimitActions,
      handleTableAction,
      changeSN
    }
  }
}
</script>

<template>
  <CContainer fluid class="appointment-pass-view datatable-container mb-5">
    <Loader :is-processing="state.isLoading" />
    <CRow class="mt-5">
      <CCol md="12">
        <ActionPanel
          @tab-clicked="setActiveTableTab"
          class="mb-3"
          show-sort
          show-search
          :tabs="tableTabs"
          @on-search="searchByQuery"
          @on-sort="toggleFilter"
        >
          <template #bottom-content>
            <CRow class="mt-3" v-if="state.showFilter">
              <StudentAptPassFilters />
            </CRow>
          </template>
        </ActionPanel>

        <PerPage
          class="mb-3"
          v-if="pagination"
          :pagination="pagination"
          :showing-entries="true"
          @on-page-change="setPerPage"
        />

        <DataTable
          data-test-id="student-app-pass-table"
          v-if="appointmentPasses"
          class="student-app-pass"
          add-table-classes="app-pass-datatable"
          :items="appointmentPasses"
          :fields="state.dataTableFields"
          :sorter="{ external: true }"
          :no-items-view="{
            noResults: 'No items available',
            noItems: 'No items available'
          }"
          @update:sorter-value="sortByColumn"
        >
          <template #confirm_cancel-header>
            <div>
              <div class="lh-normal">
                Confirmed <span class="flaticon-check text-green" />
                <br />/Cancelled <span class="flaticon-cancel text-red" />
              </div>
            </div>
          </template>
          <!-- Dates -->
          <template #for_date="{ item }">
            <td class="m-0 p-0 h-100">
              <div class="d-flex align-items-center">
                <div
                  data-test-id="student-apt-pass-dates"
                  class="status-mark me-3"
                  :class="[getPassStatus(item).class]"
                />
                <div
                  data-test-id="student-apt-pass-dates-repeat"
                  v-if="item.recurrence_appointment_pass"
                  class="recurrence-column"
                >
                  <div v-if="item.recurrence_appointment_pass.recurrence_type">
                    <div class="">
                      <b>{{
                        $helpers.transformDate(item.for_date, "MMM DD YYYY")
                      }}</b>
                    </div>
                    <div>
                      <span class="text-capitalize">{{
                        item.recurrence_appointment_pass.recurrence_type
                      }}</span>
                    </div>
                    <div
                      v-if="item.recurrence_appointment_pass.recurrence_days"
                      class="weekdays-options-container d-flex mt-1"
                    >
                      <div
                        v-for="(day, index) in JSON.parse(
                          item.recurrence_appointment_pass.recurrence_days
                        ).days"
                        :key="index"
                        class="weekday-box active me-1"
                      >
                        {{ day.substring(0, 2) }}
                      </div>
                    </div>
                    <div
                      v-if="item.recurrence_appointment_pass.recurrence_end_at"
                      class="mt-1"
                    >
                      {{
                        $helpers.transformDate(
                          item.recurrence_appointment_pass.for_date,
                          "MMM DD YYYY"
                        ) +
                        " > " +
                        $helpers.transformDate(
                          item.recurrence_appointment_pass.recurrence_end_at,
                          "MMM DD YYYY"
                        )
                      }}
                    </div>
                  </div>
                </div>
                <div data-test-id="student-apt-pass-dates-repeat" v-else>
                  <p class="mb-0">
                    <b>{{
                      item.for_date
                        ? $helpers.transformDate(item.for_date, "MMM DD YYYY")
                        : ""
                    }}</b>
                  </p>
                  <span>Does not repeat</span>
                </div>
              </div>
            </td>
          </template>
          <!-- Status -->
          <template #string_status="{ item }">
            <td>
              <div
                data-test-id="student-apt-pass-status"
                class="pass-status p-3 rounded-30 text-center"
                :class="[getPassStatus(item).class]"
              >
                <b class="text-uppercase">{{ getPassStatus(item).label }}</b>
              </div>
            </td>
          </template>
          <!-- Period -->
          <template #period="{ item }">
            <td>
              <div data-test-id="student-apt-pass-period">
                <strong class="d-flex mb-1" v-if="item.for_date">{{
                  item.for_date
                    ? $helpers.transformDate(item.for_date, "h:mm A")
                    : ""
                }}</strong>
                <span v-if="item.period">{{ item.period.name }}</span>
              </div>
            </td>
          </template>
          <!-- From -->
          <template #from="{ item }">
            <td class="pass-time-info">
              <div data-test-id="student-apt-pass-from" class="d-flex">
                <b
                  v-if="
                    item.from_type && item.from_type === 'App\\Models\\User'
                  "
                  >{{
                    item.from
                      ? item.from.first_name + " " + item.from.last_name
                      : "-"
                  }}</b
                >
                <b v-else>{{ item.from ? item.from.name : "-" }}</b>
                <span
                  v-if="item.acknowledged_by_user"
                  class="flaticon-check text-green"
                />
              </div>
            </td>
          </template>
          <!-- to -->
          <template #to="{ item }">
            <td data-test-id="student-apt-pass-to" class="pass-time-info">
              <b v-if="item.to_type && item.to_type === 'App\\Models\\User'">{{
                item.to ? item.to.first_name + " " + item.to.last_name : "-"
              }}</b>
              <b v-else>{{ item.to ? item.to.name : "-" }}</b>
            </td>
          </template>
          <!-- Confirmed -->
          <template #confirm_cancel="{ item }">
            <td>
              <div
                data-test-id="student-apt-pass-confirm-cancel"
                v-if="isCreatedByUser(item)"
              >
                <div v-if="item.confirmed_by_user">
                  <span>{{
                    item.confirmed_by_user.first_name +
                    " " +
                    item.confirmed_by_user.last_name
                  }}</span>
                  <p v-if="item.confirmed_by_teacher_at">
                    <span class="flaticon-check text-green" />
                    {{
                      $helpers.transformDate(
                        item.confirmed_by_teacher_at,
                        "MMM DD YYYY h:mm A"
                      )
                    }}
                  </p>
                </div>
                <div v-if="item.canceled_by_user">
                  <span>
                    {{
                      item.canceled_by_user.first_name +
                      " " +
                      item.canceled_by_user.last_name
                    }}</span
                  >
                  <p v-if="item.canceled_by_user">
                    <span class="flaticon-cancel text-red" />
                    {{
                      $helpers.transformDate(
                        item.canceled_at,
                        "MMM DD YYYY h:mm A"
                      )
                    }}
                  </p>
                </div>
              </div>
            </td>
          </template>
          <!-- Actions -->
          <template #actions="{ item }">
            <td data-test-id="student-apt-pass-actions">
              <div class="table-actions mb-2">
                <div
                  v-if="
                    canPassBeCanceled(item) && isCancelVisible(item.for_date)
                  "
                  class="d-flex"
                >
                  <button
                    data-test-id="stu-cancel-apt-pass-btn"
                    class="btn btn-cancel"
                    @click="setActiveModal('cancelPassReason', item)"
                  >
                    cancel
                  </button>
                </div>
              </div>
              <div v-if="item.acknowledged_by_teacher_at">
                Acknowledge:
                {{
                  $helpers.transformDate(
                    item.acknowledged_by_teacher_at,
                    "MMM DD YYYY h:mm A"
                  )
                }}
              </div>

              <div v-if="item.created_at">
                <div
                  v-if="
                    item.created_by_user && item.created_by_user.role_id == 1
                  "
                >
                  Requested:
                  {{
                    $helpers.transformDate(
                      item.created_at,
                      "MMM DD YYYY h:mm A"
                    )
                  }}
                </div>
                <div v-else>
                  Created:
                  {{
                    $helpers.transformDate(
                      item.created_at,
                      "MMM DD YYYY h:mm A"
                    )
                  }}
                </div>
              </div>

              <div v-if="item.expired_at">
                Expired:
                {{
                  $helpers.transformDate(item.expired_at, "MMM DD YYYY h:mm A")
                }}
              </div>
            </td>
          </template>

          <template #comments="{ item }">
            <td class="comments-column">
              <div
                v-if="item.latest_comment"
                class="d-flex"
                data-test-id="student-apt-pass-comments"
              >
                <div
                  v-if="activeTab !== 'prevSevenDays'"
                  class="comments-count me-2"
                  @click="setActiveModal('comments', item)"
                >
                  <i class="fi flaticon-chat" /><b>{{
                    item.latest_comment.count || 0
                  }}</b>
                </div>
                <div
                  v-if="item.latest_comment && activeTab !== 'prevSevenDays'"
                  class="comments-list cursor-pointer"
                  @click="setActiveModal('comments', item)"
                >
                  <div class="comment cursor-pointer">
                    {{ item.latest_comment.comment }}
                  </div>
                </div>
              </div>
              <div
                v-if="
                  (item.is_recurrence || activeTab === 'prevSevenDays') &&
                  item.latest_comment
                "
                class="comments-list"
              >
                <div class="comment">
                  {{ item.latest_comment.comment }}
                </div>
              </div>
            </td>
          </template>
        </DataTable>
        <Pagination
          v-if="
            pagination.pages &&
            pagination.pages > 1 &&
            pagination.per_page.value != 75
          "
          :active-page="pagination.activePage"
          :pages="pagination.pages"
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          size="lg"
          align="center"
          @update:active-page="setActivePage"
        ></Pagination>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>
import Loader from "@/v3components/shared/Loader/Loader.vue"
import tableConfig from "./appointmentsTableConfig.js"
import CommentsComponent from "@/v3components/CommentsComponent"
import StudentAptPassFilters from "./StudentAptPassFilters"
import AptPassCancelation from "@/v3components/Forms/AptPassCancelation"
import moment from "moment-timezone"
import { useStore } from "vuex"
import { reactive, computed, watch, onMounted, onUnmounted, inject } from "vue"
import helpersJS from "@/helpers"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import PerPage from "@/v3components/shared/DataTable/Perpage"
import DataTable from "@/v3components/shared/DataTable/DataTable"
import Pagination from "@/v3components/shared/DataTable/Pagination"

export default {
  name: "StudentAptPassDataTable",
  components: {
    Loader,
    DataTable,
    Pagination,
    ActionPanel,
    StudentAptPassFilters,
    PerPage
  },
  setup() {
    const store = useStore()

    const modal = inject("modal")

    const state = reactive({
      ...tableConfig,
      isLoading: false,
      activeTab: null,
      modal: {
        isVisible: {
          comments: false,
          editApptPass: false
        },
        activePass: null
      },
      waiting: false,
      showFilter: true
    })

    const periods = computed(() => store.getters["periods/formListStudents"])
    const editablePass = computed(
      () => store.getters["studentAptPass/editablePass"]
    )
    const activeTab = computed(() => store.getters["studentAptPass/activeTab"])
    const pagination = computed(
      () => store.getters["studentAptPass/pagination"]
    )
    const searchQuery = computed(
      () => store.getters["studentAptPass/searchQuery"]
    )
    const appointmentPasses = computed(
      () => store.getters["studentAptPass/appointmentPasses"]
    )
    const currentUser = computed(() => store.getters["authentication/user"])
    const isActiveDashboardUpdate = computed(
      () => store.getters["dashboardTable/isActiveDashboardUpdate"]
    )
    const globalTime = computed(() => store.getters["layout/globalTime"])

    const tableTabs = [
      { label: "Next 7 Days", value: "nextSevenDays" },
      { label: "Beyond 7 Days", value: "prevSevenDays" }
    ]

    watch(globalTime, () => {
      store.commit(
        "studentAptPass/SET_APPOINTMENT_PASSES",
        appointmentPasses.value
      )
    })

    onMounted(() => {
      store.dispatch("schools/getActiveModules", {
        clearCacheEntry: true
      })
      state.activeTab = tableTabs[0]
      store.commit("studentAptPass/SET_ACTIVE_TAB", "nextSevenDays")
      initLazyLoad()
    })

    onUnmounted(() => {
      resetSearchQuery()
    })

    const searchByQuery = (value, type) => {
      if (window.searchByQueryTimeout) clearTimeout(window.searchByQueryTimeout)

      window.searchByQueryTimeout = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 1000)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        store.commit("studentAptPass/SET_SEARCH_QUERY", "")
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  if (searchQuery.value) {
                    store.commit(
                      "studentAptPass/SET_SEARCH_QUERY",
                      searchQuery.value + `,${col}:${columnData.value}`
                    )
                  } else {
                    store.commit(
                      "studentAptPass/SET_SEARCH_QUERY",
                      `${col}:${columnData.value}`
                    )
                  }
                })
              }
            }
          }
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          if (query) {
            store.commit("studentAptPass/SET_SEARCH_QUERY", query)
          }
        }
        store.dispatch("studentAptPass/getAppointmentPasses")
        store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
      }
    }

    const resetSearchQuery = () => {
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", true)
      store.commit("studentAptPass/SET_SEARCH_QUERY", "")
      store.dispatch("studentAptPass/getAppointmentPasses")
      state.columnSearchKeys = {
        globalQuery: "",
        user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
        recurrence_appointment_pass: {
          value: "",
          column: ["search_date_string"],
          sortBy: "for_date"
        },
        period: { value: "", column: ["period.name"], sortBy: "period.name" },
        from: { value: "", column: ["from.name"], sortBy: "from.name" },
        to: { value: "", column: ["to.name"], sortBy: "to.name" },
        created_by_user: {
          value: "",
          column: ["created_by_user.name"],
          sortBy: "created_by_user.name"
        },
        comments: {
          value: "",
          column:
            activeTab.value == "prevSevenDays"
              ? ["reason"]
              : ["latest_comment.comment"],
          sortBy: "latest_comment.comment"
        }
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      store.commit(
        "studentAptPass/SET_SORT_BY",
        `${columnName}:${column.asc ? "asc" : "desc"}`
      )
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
    }

    const closeModal = () => {
      state.modal = {
        isVisible: {
          comments: false
        },
        activePass: null
      }
    }

    const setActiveModal = (modalName, pass) => {
      if (pass) {
        if (modalName === "comments") {
          modal.open(CommentsComponent, {
            size: "md",
            title: "Comments",
            props: {
              toID: pass.id,
              commentableType: "App\\Models\\AppointmentPass"
            }
          })
        }
        if (modalName === "cancelPassReason") {
          modal.open(AptPassCancelation, {
            size: "md",
            title: "Cancel Pass",
            props: {
              onlySimpleCancelation: true,
              pass: pass
            }
          })
        }
      }
    }

    const onPassEdit = (pass) => {
      store.commit("studentAptPass/SET_EDITABLE_PASS", pass)
      helpersJS.scrollToTop()
    }

    const exportCSVDashboardData = () => {
      state.isLoading = true
      store
        .dispatch("studentAptPass/getCSVAppointmentPasses")
        .then((response) => {
          if (response.data) {
            $download.downloadCSV(response.data, "appointment-passes")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const initLazyLoad = () => {
      const mainCnt = document.getElementsByClassName(
        "main-scroll-container"
      )[0]
      if (mainCnt) {
        mainCnt.addEventListener("scroll", () => {
          if (
            pagination.value.per_page.value == 75 &&
            pagination.value.total > appointmentPasses.value.length &&
            !state.waiting
          ) {
            if (
              mainCnt.offsetHeight + mainCnt.scrollTop >=
              mainCnt.scrollHeight - 1000
            ) {
              store.commit(
                "studentAptPass/SET_ACTIVE_PAGE",
                pagination.value.activePage + 1
              )
              state.waiting = true
              setTimeout(() => {
                state.waiting = false
              }, 1000)
            }
          }
        })
      }
    }

    const isCancelVisible = (date) => {
      const now = moment().format("YYYY-MM-DD HH:mm:ss")
      const endDate = moment(date, "YYYY-MM-DD HH:mm:ss")
        .subtract(30, "minutes")
        .format("YYYY-MM-DD HH:mm:ss")
      return now < endDate
    }

    const canPassBeCanceled = (item) => {
      return item.created_by == currentUser.value.id && item.canceled_at == null
    }

    const isCreatedByUser = (item) => {
      return item.created_by == currentUser.value.id
    }

    const getPassStatus = (pass) => {
      if (pass) {
        if (pass.is_for_confirm) {
          return {
            class: "waiting-confirmation",
            label: "Waiting Confirmation"
          }
        }
        if (pass.is_canceled) {
          return {
            class: "canceled",
            label: "Canceled"
          }
        }
        if (pass.is_for_future) {
          return {
            class: "future-apt",
            label: "Future Apt"
          }
        }
        if ((pass.is_for_today || pass.waiting_activation) && !pass.is_missed) {
          return {
            class: "today-pass",
            label: "Today's APT"
          }
        }
        if (pass.is_missed) {
          return {
            class: "missed-apt",
            label: "Missed apt"
          }
        }
        if (pass.is_ended) {
          return {
            class: "ended",
            label: "Ended"
          }
        }

        if (pass.is_in_another_pass) {
          return {
            class: "on-other-pass",
            label: "On other pass"
          }
        }
        if (pass.is_missed_request) {
          return {
            class: "missed-request",
            label: "Missed request"
          }
        }
      }
      return {
        class: "",
        label: ""
      }
    }

    const setActiveTableTab = (tab) => {
      helpersJS.scrollToTop()
      store.commit("studentAptPass/SET_ACTIVE_TAB", tab.value)
    }

    const setActivePage = (page) => {
      helpersJS.scrollToTop()
      store.commit("studentAptPass/SET_ACTIVE_PAGE", page)
    }

    const setPerPage = (perPage) => {
      store.commit("studentAptPass/SET_PER_PAGE", perPage)
    }

    const toggleFilter = () => {
      state.showFilter = !state.showFilter
    }

    return {
      state,
      periods,
      editablePass,
      activeTab,
      pagination,
      searchQuery,
      appointmentPasses,
      currentUser,
      isActiveDashboardUpdate,
      globalTime,
      searchByQuery,
      handleQuerySearch,
      resetSearchQuery,
      sortByColumn,
      closeModal,
      setActiveModal,
      onPassEdit,
      exportCSVDashboardData,
      isCancelVisible,
      canPassBeCanceled,
      isCreatedByUser,
      getPassStatus,
      setActivePage,
      tableTabs,
      setActiveTableTab,
      setPerPage,
      toggleFilter
    }
  }
}
</script>

<template>
  <div class="attendance-datatable-container">
    <ActionPanel class="mb-2"></ActionPanel>
    <DataTable :fields="tableFields" :items="attendanceFiles">
      <template #created_at="{ item }">
        <td>
          {{
            item.created_at
              ? $helpers.transformDate(item.created_at, "MM/DD/YYYY h:mm A")
              : ""
          }}
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          {{
            item.updated_at
              ? $helpers.transformDate(item.updated_at, "MM/DD/YYYY h:mm A")
              : ""
          }}
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <span
            class="px-3 text-uppercase rounded py-1 text-white fw-bold d-block text-center w-full"
            :class="{
              'bg-red-failed-status': item.status == 'FAILED',
              'bg-green-success-status': item.status == 'PROCESSED',
              'bg-blue': item.status == 'IN_PROGRESS',
              'bg-yellow': item.status == 'PENDING'
            }"
            >{{ item.status }}</span
          >
        </td>
      </template>
    </DataTable>

    <Pagination
      v-if="state && state.pagination && state.pagination.pages > 1"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    />
  </div>
</template>

<script>
import DataTable from "@/v3components/shared/DataTable/DataTable"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import { useStore } from "vuex"
import { onMounted, computed, reactive } from "vue"
import helpers from "@/helpers/index"

export default {
  name: "TardyCsvUploadTable",
  components: { DataTable, Pagination },
  setup() {
    const store = useStore()

    const state = reactive({
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      }
    })

    const tableFields = [
      { key: "filename", label: "File Name", sorter: false, filter: false },
      { key: "tardy_badge", label: "Type", sorter: false, filter: false },
      { key: "created_at", label: "Added Date", sorter: false, filter: false },
      { key: "updated_at", label: "Last Change", sorter: false, filter: false },
      { key: "status", label: "Status", sorter: false, filter: false }
    ]

    const attendanceFiles = computed(
      () => store.getters["attendance/attendanceFiles"]
    )

    onMounted(() => {
      getAttendanceFiles()
    })

    const getAttendanceFiles = () => {
      const params = {
        per_page: state.pagination.per_page.value,
        page: state.pagination.activePage
      }
      store
        .dispatch("attendance/getAttendanceFiles", params)
        .then((response) => {
          if (response && response.data && response.data.meta) {
            const data = response.data.meta

            setPagination({
              total: data.total,
              from: data.from,
              to: data.to,
              pages: Math.ceil(data.total / data.per_page),
              activePage: Number(state.pagination.activePage)
            })
          }
        })
    }
    const setPagination = (data) => {
      state.pagination = Object.assign(state.pagination, data)
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getAttendanceFiles()
      helpers.scrollToTop()
    }

    return {
      state,
      tableFields,
      setActivePage,
      attendanceFiles,
      setPagination
    }
  }
}
</script>
